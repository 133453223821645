export const defaultCountries = [
  "Canada",
  "US",
  "China",
  "France",
  "Italy",
  "United Kindom",
  "Iran",
  "Japan"
];
